
































import { Component, Vue } from 'vue-property-decorator';

import CCustomComponent from './c-custom-component';
import Comp2 from './Comp2.vue';

const today = new Date();
today.setMilliseconds(0);
today.setSeconds(0);
today.setMinutes(0);
today.setHours(0);

@Component({
    components: {
        'c-custom-component': CCustomComponent,
        'comp2': Comp2
    }
})
export default class TestPage extends Vue {
    private date = new Date();

    private get checkedDate(): Date {
        return this.date;
    }

    private set checkedDate(value: Date) {
        // console.log('`checkedDate` setter is called');
        if (value < today) {
            throw new Error(`Invalid date value "${value}"`);
        }
        this.date = value;
    }

    private onChange(newValue: Date) {
        // console.log(this);
        // console.log('onChange', newValue);
        this.checkedDate = newValue;
    }

    private test() {
        const comp = this.$refs.comp1 as CCustomComponent;
        comp.resetDate();
    }
}
