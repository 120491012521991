import { Component, Model, Prop, Vue } from 'vue-property-decorator';


const modelEvent = 'fff';

@Component
export default class CCustomComponent extends Vue {
    // #region Properties
    // #region date
    @Model(modelEvent, {
        type: Date,
        required: true
    })
    private date!: Date;
    // #endregion

    // #region date2
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    private date2!: Date | null;
    // #endregion
    // #endregion

    public resetDate() {
        const newValue = new Date();
        newValue.setMonth(-1);
        // console.log('Emitting new date:', newValue);
        this.$emit(modelEvent, newValue);
    }
}