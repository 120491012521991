





import { Component, Vue } from 'vue-property-decorator';


@Component
export default class Comp2 extends Vue {
    private text = '';

    // #region 123
    public testMethod() {
        // console.log('Test method called');
    }
    // #endregion
}
