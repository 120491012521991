







import Class from './c-custom-component';
export default Class;
